















































import {inflectToSlug, isEmpty, isPlainObject, isUndefined} from '@labor-digital/helferlein';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {isNull} from '@labor-digital/helferlein/lib/Types/isNull';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import EmptyListNotification from '../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import Icon from '../../../Component/Misc/Icon/Icon.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import {Branch} from '../../../Interface/Branch';
import {GeoCodeLocation} from '../../../Interface/GeoCodeLocation';
import {Job} from '../../../Interface/Job';
import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';
import {JobService} from '../../../Service/JobService';
import {JsonLdService} from '../../../Service/JsonLdService';

export default {
    name: 'JobLatest',
    components: {EmptyListNotification, LinkWrap, Icon, Btn},
    mixins: [LanguageSwitchMixin],
    props: {
        context: null as ContentElementContext,
        branch: null as Branch
    },
    metaInfo()
    {
        const city = this.branch?.city ?? '';

        return {
            script: [
                JsonLdService.makeJobList(this.jobs, city)
            ]
        };
    },
    data()
    {
        return {
            result: [],
            location: isNull(this.branch) || isUndefined(this.branch) ?
                    BranchAndGeoLocationService.getCurrentLocation() : this.branch
        };
    },
    mounted()
    {
        JobService.getJobsForDemandObject(
                JobService.mergeDemands(
                        this.isMainLanguage
                                ? {
                                    location: this.extractRelevantLocationFields(this.location),
                                    sorting: isUndefined(this.location) ? 'latest' : 'distance'
                                }
                                : {
                                    sorting: 'latest'
                                }
                        , this.context.data.get('demand', {})),
                {pageSize: 6}
        ).then(
                (response: Collection) => {
                    this.result = map(response.getAll(), (v) => v.getAll());
                }
        );
    },
    computed: {
        jobs(): Array<Job>
        {
            return this.result;
        },
        jobFilterUrl(): String
        {
            const location = isEmpty(this.location.name) ? '' : inflectToSlug(this.location.name.replace(/\((.*?)\)/g, ''));
            return this.context.pageContext.linkRepository.get('jobList') + '/' + location;
        }
    },
    methods: {
        onListClick()
        {
            this.$emit('listClick ' + this.location);
        },
        onJobClick(job: Job)
        {
            this.context.pageContext.router.push('/' + job.url.replace(/^(?:\/\/|[^/]+)*\//, ''));
        },
        extractRelevantLocationFields(branchOrLocation: GeoCodeLocation | Branch | null): GeoCodeLocation | null
        {
            if (!isPlainObject(branchOrLocation)) {
                return null;
            }

            return {
                latitude: branchOrLocation.latitude ?? null,
                longitude: branchOrLocation.longitude ?? null,
                address: branchOrLocation.address ?? null
            };
        }
    }
};
