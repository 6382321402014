var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobLatest" },
    [
      _vm.jobs.length > 0
        ? _c(
            "div",
            { staticClass: "jobLatest__container" },
            [
              _c(
                "ul",
                _vm._l(_vm.jobs, function (job) {
                  return _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: job.url },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onJobClick(job)
                          },
                        },
                      },
                      [
                        _c("h3", [_vm._v(_vm._s(job.title))]),
                        _vm._v(" "),
                        _c("ul", [
                          job.city
                            ? _c(
                                "li",
                                { staticClass: "city" },
                                [
                                  _c("icon", { attrs: { icon: "mapPin" } }),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(job.city) +
                                      "\n                        "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("icon", { attrs: { icon: "arrowLeft" } }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "btn",
                {
                  attrs: { href: _vm.jobFilterUrl },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onListClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("branch.allJobs")) +
                      " in " +
                      _vm._s(_vm.location.name) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _c("empty-list-notification", {
            attrs: { "has-green-background": true },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }